import React from 'react'
import Layout from "@retina-packages/retina-theme-bootstrap/packages/components/layout"
import { graphql } from 'gatsby'
import { PageWrapper, GenTestBenefitWrapperMain } from '@retina-packages/retina-theme-bootstrap/packages/css/CommonStyle'
import { CommunityResources } from './styles'
import HTMLRenderer from '@retina-packages/retina-theme-bootstrap/packages/components/HTMLRenderer'
import MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer'
import { deriveCommunityResourceVideo } from '@retina-packages/retina-theme-bootstrap/packages/helpers'
import { HomePageFooterContainer, UsHeaderContainer, FooterImageGroup, RepWrapper } from "../../../css/common/style"
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes'
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType'
import reusableDataBuilders from '@retina-packages/retina-theme-bootstrap/packages/helpers/reusableDataBuilders'
import retinaConfig from '../../../utils/retinaConfigs'
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles'
import { patientCS } from './constants'
import { deriveSiteLogo, deriveSiteMenus, fullHTMLDataBuilder } from '../../../utils'
import MultiColVideoContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MultiColVideoContainer'

/** Renders community-organizations-page template
 *
 * @param props props
 * @returns
 */
const CommunityResourcesTemplate = (props: Props) => {

  const pgPropCO: PageQueryResult = props.data
  const blocksCO = pgPropCO.nodePage.relationships.field_paragraphs_gcso
  const pgContextsCO = props.pageContext
  const htmlStaticFilesCO = {
    nonSVGImages: pgContextsCO.nonSVGImages,
    svgMediaImages: pgContextsCO.svgMediaImages,
    allMediaDocument: pgContextsCO.documents
  }

  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp: pgPropCO, siteMainMenu: patientCS.mainMenu, siteFooterMenu: patientCS.patFooterMenu })
  const audience = pgPropCO.nodePage.relationships.field_general_tags_gcso[0].name

  const blocks = blocksCO.filter((x: any) => 'relationships' in x)

  const bannerTitle = ""
  const carouselTitle = ""
  const footerTitle = patientCS.footer
  const exitPopupTitle = patientCS.exitPopup
  const hcpSwitcherTitle = patientCS.hcpSwitcher
  const topNavTitle = patientCS.topNav
  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs
  }: {
    footerText: string
    exitData: exitPopDataType
    hcpSwitcher: exitPopDataType
    topNavs: string
  } = reusableDataBuilders({ blocks, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle })

  /**
   * Page specific data building.
   */
  const siteLogos = deriveSiteLogo({ blocks: blocksCO, title: patientCS.siteLogo })
  const bannerHTML = fullHTMLDataBuilder({ blocks: blocksCO, title: patientCS.banner })

  const comResVideoSectionCO = blocks.filter((crco: any) => crco.relationships.field_reusable_paragraph.label === patientCS.video1).map(
    (crcoi: any) => deriveCommunityResourceVideo(crcoi))?.at(0)
  const allisonVideo = blocks.filter((crco: any) => crco.relationships.field_reusable_paragraph.label === patientCS.allisonVideoPara).map(
    (crcoi: any) => deriveCommunityResourceVideo(crcoi))?.at(0)
  const videoTopParaHTML = fullHTMLDataBuilder({ blocks: blocksCO, title: patientCS.videoTopPara })
  const communitySupData = fullHTMLDataBuilder({ blocks: blocksCO, title: patientCS.titleCard })
  const titleCardHTML = fullHTMLDataBuilder({ blocks: blocksCO, title: patientCS.crTitleCard })
  const allisonVideoHead = fullHTMLDataBuilder({ blocks: blocksCO, title: patientCS.allisonVideoHeading })
  const anchorLinkHTML = fullHTMLDataBuilder({ blocks: blocksCO, title: patientCS.anchorLinksPara })

  return (
    <UsHeaderContainer>
      <GenTestBenefitWrapperMain>
        <HomePageFooterContainer>
          <CommunityResources className='community-resources'>
            {props.pageContext !== null && props.pageContext.metaInfo !== null && (
              <MetaTagContainer metaData={props.pageContext.metaInfo} />
            )}
            <Layout
              title={"siteTitle"}
              location={props.location}
              data={mainMenu}
              mdata={footerMenu}
              footerData={footerText}
              audience={audience}
              exitPopData={exitData}
              hcplinks={hcpSwitcher}
              topNavigation={topNavs}
              staticMediaFiles={htmlStaticFilesCO}
              siteLogos={siteLogos}
              backToTopContent={retinaConfig.backToTopContent}
              gtmBackToTopLabel={patientCS.backToTopGTM}
              modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
              modalCloseButtonGTMLabel={retinaConfig.modalCloseButtonGTMLabel}
              hcpValidate={retinaConfig.hcpValidate}
              hcpHomeUrl={retinaConfig.hcpHomeUrl}
              preIndexUrl={retinaConfig.preIndexUrl}
              hcpPrefix={retinaConfig.hcpPrefix}
              siteHomeUrl={retinaConfig.siteHomeUrl}
              footerClassName={patientCS.footerClassName}
              cpraFooter={true}
              cpraFooterEnv={process.env.CPRA_FOOTER_ENV}
              cpraOT={true}
              cpraOTEnv={process.env.OT_ENV}
              cpraOTuuid={process.env.OT_UUID}
              languageConfig={{ ...retinaConfig.langConfig, ...patientCS.redirectLangUrl }}
              {... { ...retinaConfig.layoutAttrs.dtcAttrs, ...retinaConfig.layoutAttrs.commonAttrs }}
            >
              <PageWrapper className={`pagewrapper community-resources`}>
                <MobileBanner className="mobile-banner clinical-trials-banner">
                  <HTMLRenderer data={htmlStaticFilesCO} html={bannerHTML} tagName='section' className='internal-banner o-column--full' simpleImage={true} />
                </MobileBanner>

                <HTMLRenderer data={htmlStaticFilesCO} html={anchorLinkHTML} />
                <FooterImageGroup className='logo-section o-column--full o-bottom-space '>
                  <HTMLRenderer data={htmlStaticFilesCO} html={communitySupData} tagName='div' className='o-container' simpleImage={true} />
                </FooterImageGroup>
                <HTMLRenderer data={htmlStaticFilesCO} html={allisonVideoHead} />
                <MultiColVideoContainer
                  accountId={retinaConfig.accountId}
                  communityResourcesVideoSection={allisonVideo}
                  viewTranscriptBtnContent={retinaConfig.viewTranscriptBtnContent}
                  hideTranscriptBtnContent={retinaConfig.hideTranscriptBtnContent}
                  {...patientCS.allisonVideoAttrs}
                />
                <RepWrapper className='o-container'>
                  <HTMLRenderer data={htmlStaticFilesCO} html={videoTopParaHTML} tagName='div' className='o-inner' />
                </RepWrapper>
                <MultiColVideoContainer
                  accountId={retinaConfig.accountId}
                  communityResourcesVideoSection={comResVideoSectionCO}
                  viewTranscriptBtnContent={retinaConfig.viewTranscriptBtnContent}
                  hideTranscriptBtnContent={retinaConfig.hideTranscriptBtnContent}
                  {...patientCS.videoAttrs}
                />
                <HTMLRenderer data={htmlStaticFilesCO} html={titleCardHTML} />
              </PageWrapper>
            </Layout>
          </CommunityResources>
        </HomePageFooterContainer>
      </GenTestBenefitWrapperMain>
    </UsHeaderContainer>
  )
}

export default CommunityResourcesTemplate

export const pageQuery = graphql`
query($id: Int!) {
  nodePage(drupal_internal__nid: {eq: $id}) {
    relationships {
      ... CommunityResourcePageQuery
    }
  }
}
`
