export const patientCS: any = {
  mainMenu: "Main Navigational Menu Consumer GCSO - Global",
  footerMenu: "Footer Navigation Menu Consumer GCSO - Global",
  banner: "Banner - HTML - Community Resources - DTC - US Global",
  footer: "Retina Global Footer Consumer - US - Global",
  exitPopup: "Retina Canada Exit Popup New - Global",
  hcpSwitcher: "HCP Switcher Modal Pop Up - Global",
  topNav: "Top Navigational Menu Consumer US - Global",
  anchorLinksPara: "Anchor Link - Community Resources - Retina Global",
  video1: "Community Resources Video Section - Retina Global",
  allisonVideoPara: "Community Resources Allison Testimonial - Retina Global",
  allisonVideoHeading: "Allison Testimonial Video heading - Community Resources - Retina Global",
  siteLogo: "Site logos - Global",
  videoTopPara: "Community Resources Video Thumbnail Heading New - Retina Global",
  crTitleCard: "Community Resources Title Card - Retina Global",
  titleCard: "Community Resources - Education, Support, & Community Full HTML - Retina Global",
  redirectLangUrl: { "en": "/community-resources", "fr": "/fr/community-resources" },
  backToTopGTM: "Back to top - community resources",
  videoAttrs: {
    viewTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "Hide transcript - Genes 101",
        "className": "gtm-accordion",
        "aria-label": "Hide transcript for Genes 101"
      },
      {
        "data-gtm-event-label": "Hide transcript - Inheritance 101",
        "className": "gtm-accordion",
        "aria-label": "Hide transcript for Inheritance 101"
      }
    ],
    hideTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "Hide transcript - Genes 101",
        "className": "gtm-accordion",
        "aria-label": "Hide transcript for Genes 101"
      },
      {
        "data-gtm-event-label": "Hide transcript - Inheritance 101",
        "className": "gtm-accordion",
        "aria-label": "Hide transcript for Inheritance 101"
      }
    ]
  },
  allisonVideoAttrs: {
    viewTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "Hide transcript - The genetic testing story of Allison",
        "className": "gtm-accordion",
        "aria-label": "Hide transcript for The genetic testing story of Allison"
      }
    ],
    hideTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "Hide transcript - The genetic testing story of Allison",
        "className": "gtm-accordion",
        "aria-label": "Hide transcript for The genetic testing story of Allison"
      }
    ]
  },
  footerClassName: "community-resources-footer"
}
