import styled from 'styled-components';
import { device } from '@retina-packages/retina-theme-bootstrap/packages/css/device';
import {font,color} from "../../../css/common/variables";
export const AffordableWrapper = styled.div`
	padding: 0 0 3rem 0;

	@media ${device.desktopsignup} {
		padding: 0 5rem 2rem 5rem;
	}

	.o-container {
		margin-bottom: 40px;

		@media ${device.ipadLandscapemin} {
			margin-bottom: 90px;
		}
		@media ${device.desktopsignup} {
			margin-bottom: 150px;
		}
	}


	.text-bg-box {
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
		background-color: #8000BE;
		padding-bottom: 15px !important;
		padding: 0 2.7rem;
		color: #ffffff;

		@media ${device.desktopsignup} {
			padding-bottom: 45px !important;
		}

		.o-header--h2 {
			font-size: 25px;
			font-weight: bold;
			line-height: 1.2;
			text-align: center;
			Padding: 3rem 0;
			color: #ffffff;
			margin-bottom: 0;
			font-family: ${font.gotham_bold};

			@media ${device.desktopsignup} {
				font-size: 42px;
				line-height: 1.19;
				padding: 5rem 0 1rem;
			}
		}
		.affordable-section {
			text-align: center;
			margin-bottom: 2rem;

			@media ${device.desktopsignup} {
				margin-bottom: 3rem;
			}

			.o-column--full {
				font-size: 18px;
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.22;
				letter-spacing: normal;
				color: #ffffff;

				@media ${device.desktopsignup} {
					font-size: 22px;
					line-height: 1.45;
				}

				&:after {
					content: '';
					width: 18px;
					height: 18px;
					display: inline-block;
					margin-left: 5px;
					position: relative;
    			top: 2px;
				}

			}
		}
		p {
			font-size: 18px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.22;
			margin-bottom: 0;
			font-family: ${font.gotham};
			padding: 10px 0 14px;
			text-align: center;

			&.disc-para{
				font-family: ${font.gotham_book};
				font-size: 18px;
				line-height: 1.2;

				@media ${device.desktopsignup} {
					font-size: 22px;
					line-height: 1.45;
					margin-bottom: 7px;
					padding: 10px 3.8rem 14px;
				}
			}
			&.condition-text {
				font-size: 14px;
				line-height: 1.22;
				font-family: ${font.gotham_book};
				@media ${device.desktopsignup} {
					font-size: 18px;
  					line-height: 1.56;
				}
			}
			&.o-title-text {
				padding: 10px 15px 14px;

				@media ${device.desktopsignup} {
					font-size: 27px;
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.41;
					margin-bottom: 17px;
					padding: 10px 17rem 14px;
				}
			}
		}

	}
.affordable-container {
	padding-bottom: 5rem;
	color: ${color.black};


	.header {
		font-size: 2.5rem;
    line-height: 3rem;
		font-family: ${font.gotham};

		@media ${device.ipadLandscapemin} {
			font-size: 4.2rem;
    	line-height: 5rem;
		}
	}
	.affordable-para {
		font-size: 1.8rem;
    line-height: 2.2rem;
		font-weight: 500;

		@media ${device.ipadLandscapemin} {
			font-size: 2.7rem;
    	line-height: 3.4rem;
		}
	}
	.link-text {
		font-size: 1.8rem;
    line-height: 2.2rem;
		font-weight: 500;
		color: ${color.black};

		@media ${device.ipadLandscapemin} {
			font-size: 2.2rem;
    	line-height: 3.2rem;
		}

		&:hover {
			font-weight: 700;
		}
	}
	.condition-text {
		font-size: 1.4rem;
		font-family: ${font.gotham_book};
    font-weight: 400;
		padding: 24px 0px 42px;

		@media ${device.ipadLandscapemin} {
			font-size: 1.8rem;
    	line-height: 2.6rem;
		}
	}
}
`;

export const CommunityResources = styled.div`
.community-resources {
	.title-card {
		margin-bottom: 120px;

		.o-inner {
			h2 {
				@media ${device.tablet} and ${device.laptopair2max} {
					margin-bottom: 0;
				}
			}
			.footer-block {
				.footer-inner-block {

					.media {
						a {
							padding: 14px 22px;
							font-size: 1.8rem;
							line-height: 2.2rem;

							@media ${device.ipadLandscapemin} {
								line-height: 1.6rem;
								font-size: 2.2rem;
							}
						}
					}

					p,h3 {
						max-width: 394px;
						@media ${device.tablet} and ${device.laptopair2max} {
							max-width: 400px;
						}
					}
					.o-common-title,h3 {
						font-size: 2.7rem;
						line-height: 3.8rem;
						color: #8000BE;
						text-align: center;
						font-weight: 700;
						margin: 0;

						@media ${device.laptopMedium} {
							font-size: 2rem;
    					line-height: 1.2;
							padding-bottom: 15px;
						}
					}

					@media ${device.ipadLandscape} {
						border-radius: 10px;
						padding-top: 28px;
						padding-bottom: 16px;
						width: 100%;
						padding-left: 16px;
						padding-right: 16px;
						margin-left: 0;
						margin-top: 20px;
					}
				}
			}
		}
		.genetic-small-paragraph {
			@media ${device.ipadLandscape} {
				font-size: 1.8rem;
    		line-height: 2.2rem;
				padding-bottom: 8px;
			}
		}
	}

	.community-title-card {
		@media ${device.ipadLandscape} {
			margin-bottom: 0;
		}
		@media ${device.laptopMedium} {
			margin-bottom: 180px;
		}
	}

	.community-resources-main-heading {
		padding: 75px 48px 40px 48px;

		@media ${device.laptopMedium} {
			padding: 28px 0 10px 0;
		}

		@media ${device.laptopPros} {
			padding-left: 2.7rem;
			padding-right: 2.7rem;
		}
		p {
			font-size: 4.2rem;
			line-height: 5rem;
			color: #000000;
			text-align: center;
			font-weight: 700;
			margin: auto;

			strong {
				font-weight: 700;
			}

			@media ${device.laptopMedium} {
				font-size: 2.5rem;
    			line-height: 3rem;

				strong {
					font-weight: 700;
				}
			}
		}
		.Anchor-heading-style {
			font-size: 2.5rem;
    	line-height: 1.2;
			font-weight: 700;
			text-align: center;
			margin-bottom: 0;

			@media ${device.ipadLandscapemin} {
				font-size: 4.2rem;
			}

			strong {
				font-weight: 700;
			}
		}
	}

	.resource-anchor-links-block {
		padding: 20px 48px 60px 48px;

		@media ${device.laptopPros} {
			padding-left: 2.7rem;
			padding-right: 2.7rem;
			padding-bottom: 20px;
		}
		@media ${device.laptopMedium} {
			display: none;
		}
		.resource-links {
			display: flex;
			justify-content: space-around;
			width: 80%;
    	margin: auto;

			@media ${device.laptopMedium} {
				width: 100%;
			}

			a {
				background-color: #F7F7F7;
				position: relative;
				padding: 10px 20px 10px 35px;
				text-decoration: none;
				font-weight: 500;
				color: #8000BE;
				text-align: center;
				font-size: 2.2rem;
				line-height: 3.8rem;

				&:after {
					content: '';
					width: 30px;
          height: 26px;
					display: inline-block;
					margin-left: 10px;
					position: relative;
					top: 2px;
					vertical-align: middle;
				}

				@media ${device.desktopsignup} {
					min-width: 260px;
				}
				@media ${device.laptopair2max} {
					min-width: 180px;
					font-size: 1.8rem;
					line-height: 2.7rem;
					padding: 10px 20px;
				}
				@media ${device.laptopPros} {
					min-width: unset;
    			padding: 10px 10px;
					font-size: 1.2rem;
    			line-height: 2.2rem;
				}
				@media ${device.smallMobMax} {
					font-size: 1rem;
    			line-height: 2rem;
				}
			}
		}
	}

	#be-inspired {
		font-size: 4.2rem;
    font-weight: 700;
    line-height: 5rem;
    color: #000000;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 56px;

		@media ${device.laptopMedium} {
			padding-top: 28px;
			padding-bottom: 10px;
			margin-bottom: 0;
			font-size: 2.5rem;
    	line-height: 3rem;
		}
		@media ${device.laptopPros} {
			padding-left: 2.7rem;
			padding-right: 2.7rem;
		}
	}

	.logo-section {
		position: relative;
		padding-bottom: 88px;

		&:before {
			content: "";
			background-color: #00FFD9;
			position: absolute;
			top: 120px;
			left: 0;
			height: calc(100% - 240px);
			width: 100%;
		}

		.connect--cover-bg-wrapper {
			background-color: #F7F7F7;
			box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
   	 	position: relative;
			padding-bottom: 75px;

			&:before {
				content: "";
				background: linear-gradient(90deg, #8000be 0%, #594dc6 35%, #17d0d4 64%, #00ffd9 100%);
				position: absolute;
				top: 0;
				width: 100%;
				height: 16px;
				left: 0;
				right: 0;
			}

			@media ${device.laptopMedium} {
				padding-bottom: 0;

			}
			.o-inner {
				padding-left: 100px;
    		padding-right: 100px;

				@media ${device.laptopMedium} {
					padding-left: 0;
					padding-right: 0;
				}

				.btm-logo-block {
					@media ${device.laptopMedium} {
						padding-bottom: 8.8rem;
					}
				}

				.o-internal--h2 {
					padding: 88px 48px 40px 48px;

					@media ${device.laptopMedium} {
						padding: 35px 42px 32px 42px;
					}

					.logo-section-find {
						margin-bottom: 0;
					}
				}
				.o-other-texts {
					p {
						&.logo-section-connect {
							padding: 0 42px 32px 42px;

							@media ${device.ipadLandscapemin} {
								padding: 0 0 44px 0;
							}
						}
					}
				}
				.logo-section-info {
					text-align: center;
					width: 70%;
					margin: auto;
					@media ${device.ipadLandscapemin} {
						width: 100%;
					}

					strong {
						color: #8000BE;
						font-weight: 700;
						font-size: 2rem;
						line-height: 2.4rem;

						@media ${device.ipadLandscapemin} {
							font-weight: 700;
							color: #8000BE;
							text-align: center;
							font-size: 2.7rem;
							line-height: 3.8rem;
						}

					}
				}
			}
		}

		.logo-block {
			ul {
				display: flex;
				justify-content: center;
				width: 100% !important;

				li {
					height: 187px;
					line-height: 187px;
					width: 25% !important;
					text-align: center;
					display: flex;
					align-items: center;
					justify-content: center;

					@media ${device.laptopMedium} {
						width: 50% !important;
						padding: 15px;
						height: 100px;
						line-height: 25px;
					}

					&:last-child {
						img {
							max-width: 100%;
						}
					}

					img {
						vertical-align: middle;
						text-align: center;
						margin: auto;

						@media ${device.laptopMedium} {
							max-height: 75px;
							width: 100%;
						}

						@media ${device.ipadLandscapemin} and ${device.midDesktopMax} {
							width: 100%;
							padding: 0 10px;
						}
					}
				}
			}
		}

		@media ${device.ipadLandscape} {
			padding-top: 43px;
			padding-bottom: 30px;
		}
		.p {
			@media ${device.ipadLandscape} {
				padding: 19px 4px 21px 4px;
			}
		}
		.o-text-wrap {
			.logo-section-edu {
				text-align: center;
				margin-bottom: 0;

				@media ${device.laptopMedium} {
					width: 100%;
					padding: 0 42px 8px 42px;
				}

				strong {
					font-weight: 700;
					color: #8000BE;
					@media ${device.ipadLandscapemin} {
						font-size: 2.7rem;
						line-height: 1.41;
					}

				}
			}
			.logo-section-find {
				@media ${device.laptopMedium} {
					margin-bottom: 0;
				}
			}
			.ext-notice {
				line-height: 2.7rem;

				@media ${device.laptopMedium} {
					font-size: 14px;
					line-height: 2.1rem !important;
					padding: 0 42px 32px 42px;
				}
				@media ${device.ipadLandscape} {
					font-size: 14px;
				}
			}
		}
		.o-inner {
			.o-header--h3 {
				font-family: ${font.gotham};
				.logo-section-info {
					strong {
						@media ${device.laptopMedium} {
							font-weight: normal;
							padding-bottom: 24px;
							width: 70%;
							margin: auto;
							font-size: 2rem;
							line-height: 2.4rem;
							padding: 48px 0 24px;
						}
					}
				}
			}
			.btm-logo-block {
				ul {
					li {
						img {
							vertical-align: middle;
							text-align: center;
							margin: auto;
							max-width: 100%;

							@media ${device.laptopMedium} {
								width: 143.172px;
								max-height: 75px;
							}
						}
						@media ${device.laptopMedium} {
							width: 50%;
							padding: 15px;
							height: 100px;
							line-height: 25px;
						}
					}
				}
			}
		}

		.o-image-wrapper-main {
			@media ${device.laptopMedium} {
				padding: 0 1.5rem;
			}
		}
	}
}
	.footer {
		.footercontainer {
			margin-top: 63px;
			padding-top: 0;
			padding-bottom: 48px;
			@media ${device.laptopMedium} {
				padding-bottom: 18px;
			}
			footer {
				@media ${device.mobileStart} and ${device.tabletMax} {
					padding: 0 20px;
				}
				.col-lg-4 {
					.gatsby-image-wrapper {
						@media ${device.laptopMedium} {
							margin-top: 0;
						}
					}
				}
				&>.row {
					padding: 10.5rem 0 0 0 !important;

					@media ${device.miniDesktop} {
						padding: 90px 0 0;
					}
					@media ${device.laptopMedium} {
						padding: 6rem 0 0 0 !important;
					}
				}
			}
		}
	}
	footer {
		.row {
			padding-top: 0;
		}
		.left-footer {
			.navbar {
				margin-bottom: 0;
				padding-bottom: 0;
				padding-top: 0;
			}
			.o-column--full {
				padding-top: 31px;
				margin-bottom: 0;

				&:last-child {
					padding-top: 19px;
				}
			}
			.o-copyright {
				margin-bottom: 0;
				.o-column--full {
					padding-top: 31px;
					@media ${device.laptopMedium} {
						padding-top: 15px;
					}
				}
			}
			.o-copyright {
				.o-column--full {
					margin-bottom: 0;
					line-height: 15px;
					@media ${device.laptopMedium} {
						line-height: 2rem;
					}
				}
			}

			.FooterText1 {
				margin-bottom: 0;
				.copyright-footer {
					padding-top: 31px;
					@media ${device.laptopMedium} {
						padding-top: 15px;
					}
					margin-bottom: 0;
					a {
						line-height: 2rem;
					}
				}
				.extlink {
					color: #00FFD9;
				}
			}
		}
	}

	.pagewrapper {
		&:before {
			height: calc(100% + 50px);

			@media ${device.laptop} and ${device.ipadair2} {
				height: calc(100% - 0px);
			}
		}
	}
`;
